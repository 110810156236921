// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels") 
require("jquery")
require("turbolinks")
require('jquery-validation')
require("bootstrap")
require("bootstrap-datepicker")
require("data-confirm-modal")
// require("bootstrap-select")
require("perfect-scrollbar/dist/perfect-scrollbar.common.js");
import PerfectScrollbar from 'perfect-scrollbar';
require("chart.js/dist/Chart.min.js");
require("datatables.net/js/jquery.dataTables.min.js");
require("datatables.net-bs4/js/dataTables.bootstrap4.min.js");
require("./dashboard.js");
require("./off-canvas.js");
require("./hoverable-collapse.js");
require("./template.js");
require("./settings.js");
require("./todolist.js");
require("./file-upload.js");
require("./chart.js");
require("./Chart.roundedBarCharts.js");
require("jquery-roadmap/dist/jquery.roadmap.min.js")
require("jquery-roadmap/dist/jquery.roadmap.min.css")


import '../styles/styles'
// import "~ti-icons/css/themify-icons.css";




    
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../../assets/images', true)
const imagePath = (name) => images(name, true)
